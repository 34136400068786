<template>
    <div>
        <div id="naverIdLogin" hidden></div>
    </div>
</template>

<script>
import { naverService } from '@/assets/js/naverLogin.js';
export default {
  name: 'naverLogin',
  mounted () {
    naverService().getUserInfo();
  }
};
</script>
