import store from "@/store";
import router from "@/router";
import axios from "axios";
import VueCookies from "vue-cookies";

export const naverService = () => {
  let mode = '';
  const url = window.location.host;
  url.indexOf('localhost') !== -1 || url.indexOf('dev') !== -1 ? mode = 'dev' : mode = 'prod';
  const naverLogin = new window.naver.LoginWithNaverId({
    clientId: process.env.VUE_APP_VISTOR_NAVER_CRIENT_ID,
    callbackUrl: mode === 'dev' ? "https://dev.www.aplayz.co.kr/naverLogin" : "https://www.aplayz.co.kr/naverLogin",
    isPopup: false,
    loginButton: {
      color: "green",
      type: 3,
      height: 40
    }
  });
  const setNaver = () => {
    naverLogin.init();
  };
  const getUserInfo = () => {
    naverLogin.init();
    naverLogin.getLoginStatus((status) => {
      if (status) {
        var info = naverLogin.user;
        var comeFrom = "naver";
        var email = info.email;
        var name = info.name;
        var phoneNumber = info.mobile;
        var gender = info.gender;
        var age = info.age;
        var birthDate = info.birthyear + info.birthday;

        axios
          .post("/api/visitor/welcomeVisitor", { comeFrom, email, name, phoneNumber, gender, age, birthDate })
          .then(res => {
            if (res.data.resultCd === '0000') {
              var result = res.data.result;
              // 쿠키에 id저장 - 로그인 처리에 필요
              VueCookies.set("visitorId", result.id, 60 * 10);
              router.push({ name: "visitor", params: { spaceId: store.state.spaceInfo } });
            }
          })
          .catch(err => {
            console.log(err);
          });
      } else {
        console.log("AccessToken이 올바르지 않습니다.");
      }
    });
  };
  return {
    setNaver,
    getUserInfo
  };
};
